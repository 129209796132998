li{
  text-align: left;
}
.venue-page{
    height: 100%;
    margin-bottom: 24px;
}

/* Style for the overall page layout */
.ticket-container {
    position: relative;
    padding: 2rem;
    background-color: #f0f0f0;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 72px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(135deg, #fafafa 30%, #e0e0e0);
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.8);
  }
  
  /* Header for the page */
  .ticket-header h1 {
    color: #1D1D1D;
    font-size: 2.5rem;
    margin-bottom: 0.2rem;
    text-align: center;
  }
  
  .ticket-header h2 {
    color: #666;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  /* Section for ticket pricing info */
  .ticket-info h3 {
    color: #444;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .ticket-list {
    list-style-type: none;
    padding: 0;
  }
  
  .ticket-list li {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 1rem;
    margin: 0.5rem 0;
    /* border-radius: 8px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ticket-type {
    font-weight: bold;
    color: #333;
    padding-right: 10%;
  }
  
  .ticket-price {
    color: #1D1D1D;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Button link to the ticket page */
  .ticket-link-container {
    margin-top: 2rem;
  }
  
  .ticket-link {
    display: inline-block;
    padding: 1rem 2rem;
    background-color:  #9b17cb;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    letter-spacing: 2px;
  }
  
  .ticket-link:hover {
    /* background-color: #e65a00; */
  }

  .ticket-info-container p {
    text-align: left;
    color: #1D1D1D;
  }
  .ticket-info-container a {
    /* text-align: left; */
    color: #1D1D1D;
  }
  
  @media screen and (max-width: 768px) {
    .ticket-page{
      height: fit-content;
      margin-bottom: 64px;
    }

    .ticket-container{
      position: relative;
      margin-top: 64px;
    }

    .ticket-header h1{
      margin-top: 0;
    }
    
  }